import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Sidebar from './Sidebar';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import config from '../config';
import { format } from 'date-fns';
import Modal from 'react-modal';

const Slots = () => {
  const [slots, setSlots] = useState([]);
  const [date, setDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [newSlots, setNewSlots] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  
  const generateTimeSlots = (date) => {
    const times = [];
    const startTime = new Date(date);
    startTime.setHours(9, 0, 0); // Start at 9 AM
    const endTime = new Date(date);
    endTime.setHours(21, 0, 0); // End at 5 PM
  
    while (startTime < endTime) {
      times.push(new Date(startTime));
      startTime.setMinutes(startTime.getMinutes() + 60); // 30-minute intervals
    }
    return times;
  };
  
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   setAvailableTimes(generateTimeSlots(date));
  //   setSelectedTimes([]); // Initialize as an empty array instead of null
  // };


  const handleDateChange = async (date) => {
    setSelectedDate(date);
    const formattedDate = format(date, 'yyyy-MM-dd');
  
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.get(`${config.apiBaseUrl}/api/slots`, {
        params: { date: formattedDate },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      // Map response to get existing times in "HH:mm" format
      const existingTimes = response.data.map(slot => {
        return format(new Date(slot.time), 'HH:mm:ss'); // Convert to HH:mm:ss for accurate comparison
      });
      

      console.log("Existing times: ", existingTimes + " ref "+ response.data.map(slot => slot.time));
  
      // Generate all possible times for the selected date
      const allTimes = generateTimeSlots(date).map((time) =>
        format(time, 'HH:mm')
      );
  
      // Filter out times that exist in the response
      const filteredTimes = allTimes
        .filter((time) => !existingTimes.includes(time))
        .map((timeString) => {
          const [hours, minutes] = timeString.split(':');
          const time = new Date(date);
          time.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
          return time;
        });
  
      setAvailableTimes(filteredTimes); // Update available times with the filtered list
    } catch (error) {
      console.error("Error loading slots for selected date", error);
    }
  };

  
  
  const toggleTimeSelection = (time) => {
    const isAlreadySelected = selectedTimes.some((selectedTime) => selectedTime.getTime() === time.getTime());
    
    if (isAlreadySelected) {
      setSelectedTimes(selectedTimes.filter((selectedTime) => selectedTime.getTime() !== time.getTime()));
    } else {
      setSelectedTimes([...selectedTimes, time]);
    }
  };
  
  
  const handleSaveSlot = async () => {
    if (!selectedDate || selectedTimes.length === 0) {
      alert("Please select both date and at least one time slot");
      return;
    }
    try {
      // const accessToken = localStorage.getItem('accessToken');
      console.log("here is the data");
  
      const formattedTimes = selectedTimes.map((time) => {
        const utcTime = new Date(selectedDate);
        utcTime.setUTCHours(time.getHours(), time.getMinutes(), 0);
        return format(utcTime, 'HH:mm:ss');
      });
  
      await axios.post(
        `${config.apiBaseUrl}/api/slots/add`, 
        { 
          date: format(selectedDate, 'yyyy-MM-dd'),
          times: formattedTimes
        }, 
        // {
        //   headers: {
        //     'Authorization': `Bearer ${accessToken}`
        //   }
        // }
      );
  
      setIsModalOpen(false);
      loadSlots(date);
    } catch (error) {
      console.error('Error adding slot', error);
    }
  };
  

  useEffect(() => {
    loadSlots(date);
  }, [date]);

  const loadSlots = async (date) => {
    try {
      const formattedDate = format(date, 'yyyy-MM-dd'); // Format date as '2024-08-01'
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.get(`${config.apiBaseUrl}/api/slots`, {
        params: { date: formattedDate },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      const convertedSlots = response.data.map(slot => ({
        ...slot,
        date: new Date(slot.date),
        time: (() => {
          const utcTime = new Date(slot.time);
          const localTime = new Date(slot.date);
          localTime.setHours(utcTime.getUTCHours(), utcTime.getUTCMinutes());
          return localTime;
        })()
      }));

      setSlots(convertedSlots);
    } catch (error) {
      console.error('Error loading slots', error);
    }
  };

  const handleToggleStatus = async (slotId, status) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(`${config.apiBaseUrl}/api/slots/${slotId}`, { status }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      loadSlots(date);
    } catch (error) {
      console.error('Error updating slot status', error);
    }
  };
  const handleAddSlot = () => {
    setSelectedDate(new Date());
    setAvailableTimes([]);
    setSelectedTimes([]);
    setIsModalOpen(true);
  };

  // const handleAddSlot = async () => {
  //   setSelectedDate(new Date());
  //   setAvailableTimes([]);
  //   setSelectedTimes([]);
    
  //   const formattedDate = format(new Date(), 'yyyy-MM-dd'); // Format current date
  //   const accessToken = localStorage.getItem('accessToken');
    
  //   try {
  //     const response = await axios.get(`${config.apiBaseUrl}/api/slots`, {
  //       params: { date: formattedDate },
  //       headers: {
  //         'Authorization': `Bearer ${accessToken}`
  //       }
  //     });
  
  //     const existingTimes = response.data.map((slot) => {
  //       const time = new Date(slot.time);
  //       return format(time, 'HH:mm'); // Convert to HH:mm format to compare with available times
  //     });
  
  //     const allTimes = generateTimeSlots(new Date());
  //     const filteredTimes = allTimes.filter((time) => !existingTimes.includes(format(time, 'HH:mm')));
  //     setAvailableTimes(filteredTimes);
  
  //     setIsModalOpen(true);
  //   } catch (error) {
  //     console.error("Error loading slots for the selected date", error);
  //   }
  // };
  

  const handleDeleteSlot = async (slotId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.delete(`${config.apiBaseUrl}/api/slots/${slotId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      loadSlots(date);
    } catch (error) {
      console.error('Error deleting slot', error);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="flex-1 p-6">
        <h1 className="text-2xl font-bold mb-4">Available Slots</h1>
        <div className="flex items-center mb-4 text-black">
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="yyyy-MM-dd"
            className="rounded p-2 border border-gray-300"
          />
          <button onClick={() => loadSlots(date)} className="bg-blue-500 text-white px-4 py-2 rounded ml-2">Search</button>
          <button onClick={() => setDate(new Date(date.setDate(date.getDate() - 1)))} className="bg-gray-500 text-white px-4 py-2 rounded ml-2">Previous Day</button>
          <button onClick={() => setDate(new Date(date.setDate(date.getDate() + 1)))} className="bg-gray-500 text-white px-4 py-2 rounded ml-2">Next Day</button>
          <button onClick={handleAddSlot} className="bg-green-500 text-white px-4 py-2 rounded ml-auto">Add New Slot</button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
         {slots.map(slot => (
  <div key={slot.sid} className={`p-4 rounded-md flex flex-col items-center justify-center ${slot.status ? 'bg-green-600' : 'bg-red-500'}`}>
    <h2 className="text-2xl font-bold mb-2">{format(slot.date, 'dd MMM yyyy')}</h2>
    <p className="text-lg mb-2">Time: {format(slot.time, 'HH:mm')}</p>
              {slot.status ? (
                <p className="text-lg mb-2">Status: Slot Available</p>
              ) : (
                <p className="text-lg mb-2">Status: Slot Closed</p>
              )}
              <p className="text-lg mb-2" dangerouslySetInnerHTML={{ __html: slot.appointment ? 
                `Customer Name: ${slot.appointment.name}<br>Call Name: ${slot.appointment.app_name}<br>Meeting: ${slot.appointment.link}` : 
                '' 
              }} />
              <button
                onClick={() => handleToggleStatus(slot.sid, !slot.status)}
                className={`px-4 py-2 rounded mt-2 ${slot.status ? 'bg-red-500' : 'bg-green-500'} text-white`}
              >
                {slot.status ? 'Close' : 'Open'}
              </button>
              <button onClick={() => handleDeleteSlot(slot.sid)} className="bg-gray-600 text-white px-4 py-2 rounded mt-2">Delete</button>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        appElement={document.getElementById('root')}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Add Slot Modal"
        className="bg-gray-700 p-6 rounded text-white"
      >
        <h2 className="text-2xl font-bold mb-4">Add New Slot</h2>
        <div className="mb-4">
          <label className="block mb-2">Select Date:</label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            className="rounded p-2 border border-gray-300 text-black"
          />
        </div>
        {availableTimes.length > 0 && (
          <div className="mb-4">
            <label className="block mb-2">Select Times:</label>
            <div className="grid grid-cols-4 gap-2">
  {availableTimes.map((time, index) => (
    <button
      key={index}
      onClick={() => toggleTimeSelection(time)}
      className={`p-2 rounded ${
        selectedTimes.some(selectedTime => selectedTime.getTime() === time.getTime())
          ? 'bg-blue-500 text-white'
          : 'bg-gray-200 text-black'
      }`}
    >
      {format(time, 'HH:mm')}
    </button>
  ))}
</div>

          </div>
        )}
  <button 
    onClick={handleSaveSlot} 
    className="bg-green-500 text-white px-4 py-2 rounded mt-4"
  >
    Save Slot
  </button>
</Modal>

    </div>
  );
};

export default Slots;
